<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">ข้อมูลลูกค้าทั้งหมด</h4>
      </div>
      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="4" class="pa-0">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-combobox
                v-model="filter_search_fname"
                :items="itemsFNameFilter"
                :search-input.sync="search_fname"
                color="#182839"
                :item-text="itemsNameFilterLG"
                :item-value="itemsNameFilterLG"
                label="ชื่อ"
                placeholder="พิมพ์คำค้นหา เช่น ชื่อ"
                prepend-icon="mdi-magnify"
                clearable
                return-object
                hide-no-data
                hide-selected
                single-line
                hide-details
                small-chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        ไม่พบข้อมูล "<strong>{{ search_fname }}</strong>" กดปุ่ม <kbd>Enter</kbd> เพื่อเพิ่ม
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-combobox
                v-model="filter_search_lname"
                :items="itemsLNameFilter"
                :loading="loaddata"
                :search-input.sync="search_lname"
                color="#182839"
                :item-text="itemsNameFilterLG"
                :item-value="itemsNameFilterLG"
                label="นามสกุล"
                placeholder="พิมพ์คำค้นหา เช่น นามสกุล"
                prepend-icon="mdi-magnify"
                clearable
                return-object
                hide-no-data
                hide-selected
                single-line
                hide-details
                small-chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        ไม่พบข้อมูล "<strong>{{ search_fname }}</strong>" กดปุ่ม <kbd>Enter</kbd> เพื่อเพิ่ม
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-autocomplete
                v-model="filter_search_code"
                :items="itemsCodeFilter"
                :loading="loaddata"
                :search-input.sync="search_code"
                color="#182839"
                item-text="name"
                item-value="value"
                label="รหัสลูกค้า"
                placeholder="พิมพ์คำค้นหา เช่น WG640015"
                prepend-icon="mdi-magnify"
                clearable
                return-object
                hide-no-data
                hide-selected
                single-line
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-combobox
                v-model="filter_search_phone"
                :items="itemsPhoneFilter"
                :loading="loaddata"
                :search-input.sync="search_phone"
                color="#182839"
                item-text="name"
                item-value="value"
                label="เบอร์โทรศัพท์"
                placeholder="พิมพ์คำค้นหา เช่น 0970970977"
                prepend-icon="mdi-magnify"
                clearable
                return-object
                hide-no-data
                hide-selected
                single-line
                hide-details
                small-chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        ไม่พบข้อมูล "<strong>{{ search_fname }}</strong>" กดปุ่ม <kbd>Enter</kbd> เพื่อเพิ่ม
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row
                class="pa-0"
                align="center"
                justify="space-between"
              >
                <v-col cols="12" sm="12" md="12" lg="7">
                  <v-btn
                    block
                    color="primary"
                    :loading="loadingSearchCustomer"
                    :disabled="loadingSearchCustomer"
                    @click="searchAllCustomer"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-magnify
                    </v-icon>
                    ค้นหา
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="5">
                  <v-btn
                    block
                    color="success"
                    :loading="loadingAddCustomer"
                    :disabled="loadingAddCustomer"
                    @click="goAddNewCustomer"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-plus-circle
                    </v-icon>
                    เพิ่มลูกค้าใหม่
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="8" class="d-flex align-center">
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="3" class="d-flex align-self-stretch">
                <v-card
                  elevation="1"
                  class="px-4 py-5 box-dashboard-data d-flex flex-column align-center justify-space-around"
                >
                  <span class="label-data-dashboard">จำนวนลูกค้าทั้งหมด</span>
                  <span class="title-data-dashboard">1500</span>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="3" class="d-flex align-self-stretch">
                <v-card
                  elevation="1"
                  class="px-4 py-5 box-dashboard-data d-flex flex-column align-center justify-space-around"
                >
                  <span class="label-data-dashboard">จำนวนลูกค้าใหม่</span>
                  <span class="label-data-dashboard">{{moment().locale('th').add(543, 'year').format('MMMM YYYY')}}</span>
                  <span class="title-data-dashboard">150</span>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="3" class="d-flex align-self-stretch">
                <v-card
                  elevation="1"
                  class="px-4 py-5 box-dashboard-data d-flex flex-column align-center justify-space-around"
                >
                  <span class="label-data-dashboard">จำนวน Visit ใหม่</span>
                  <span class="label-data-dashboard">{{moment().locale('th').add(543, 'year').format('MMMM YYYY')}}</span>
                  <span class="title-data-dashboard">250</span>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="3" class="d-flex align-self-stretch">
                <v-card
                  elevation="1"
                  class="px-4 py-5 box-dashboard-data d-flex flex-column align-center justify-center"
                >
                  <span class="label-data-dashboard">สมุดบันทึกเคส</span>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="filter_search"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-start">{{item.customer_code}}</td>
          <td v-if="itemsNameFilterLG === 'nameEN'" class="text-start">{{`${item.customer_fnameen} ${item.customer_lnameen}`}}</td>
          <td v-else class="text-start">{{`${item.customer_fnameth} ${item.customer_lnameth}`}}</td>
          <td v-if="itemsNameFilterLG === 'nameEN'" class="text-center">{{item.customer_nicknameen}}</td>
          <td v-else class="text-center">{{item.customer_nicknameth}}</td>
          <td class="text-center">{{moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')}}</td>
          <td class="text-end">{{formatCurrency(item.customer_paid_cost)}}</td>
          <td class="text-end">{{formatCurrency(item.customer_remain_cost)}}</td>
          <td class="text-center">
            <v-btn
              icon
              @click="viewCustomer(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data () {
    return {
      loaddata: false,
      loadingSearchCustomer: false,
      loadingAddCustomer: false,
      cansave: true,
      filter_search: '',
      select_filter_search: '',
      showDialog: false,
      dataDialog: {},
      boolAddItems: false,
      boolViewItems: false,
      boolEditItems: false,
      boolDelItems: false,
      itemsProductTypeFilter: [],
      itemsProductTypeCreate: [],
      data: [{
        _id: '000',
        customer_code: 'WG640015',
        customer_fnameth: 'ชูใจ',
        customer_lnameth: 'นามสมมติ',
        customer_fnameen: 'Chojai',
        customer_lnameen: 'Namsommud',
        customer_nicknameth: 'กุ๊ก',
        customer_nicknameen: 'Kook',
        customer_birthdate: '1990-01-12',
        customer_prepaid_cost: 0,
        customer_paid_cost: 3000,
        customer_remain_cost: 500
      }, {
        _id: '001',
        customer_code: 'WG640020',
        customer_fnameth: 'สมใจ',
        customer_lnameth: 'ใจดี',
        customer_fnameen: 'Somjai',
        customer_lnameen: 'Jaidee',
        customer_nicknameth: 'หญิง',
        customer_nicknameen: 'Ying',
        customer_birthdate: '1960-04-19',
        customer_prepaid_cost: 0,
        customer_paid_cost: 50000,
        customer_remain_cost: 0
      }, {
        _id: '002',
        customer_code: 'WG640035',
        customer_fnameth: 'สมาน',
        customer_lnameth: 'ภักดี',
        customer_fnameen: 'Saman',
        customer_lnameen: 'Phakdee',
        customer_nicknameth: 'สมาน',
        customer_nicknameen: 'Saman',
        customer_birthdate: '1944-07-21',
        customer_prepaid_cost: 0,
        customer_paid_cost: 2000,
        customer_remain_cost: 1000
      }],
      lettersEN: /^[A-Za-z]+$/,
      filter_search_fname: '',
      search_fname: null,
      itemsNameFilterLG: 'nameTH',
      itemsFNameFilter: [{
        _id: '000',
        nameTH: 'ชูใจ',
        nameEN: 'Chojai'
      }, {
        _id: '001',
        nameTH: 'สมใจ',
        nameEN: 'Somjai'
      }, {
        _id: '002',
        nameTH: 'สมาน',
        nameEN: 'Saman'
      }],
      filter_search_lname: '',
      search_lname: null,
      itemsLNameFilter: [{
        _id: '000',
        nameTH: 'นามสมมติ',
        nameEN: 'Namsommud'
      }, {
        _id: '001',
        nameTH: 'ใจดี',
        nameEN: 'Jaidee'
      }, {
        _id: '002',
        nameTH: 'ภักดี',
        nameEN: 'Phakdee'
      }],
      filter_search_code: '',
      search_code: null,
      itemsCodeFilter: [{
        _id: '000',
        name: 'WG640015',
        value: 'WG640015'
      }, {
        _id: '001',
        name: 'WG640020',
        value: 'WG640020'
      }, {
        _id: '002',
        name: 'WG640035',
        value: 'WG640035'
      }],
      filter_search_phone: '',
      search_phone: null,
      itemsPhoneFilter: [{
        _id: '000',
        name: '0812345678',
        value: '0812345678'
      }, {
        _id: '001',
        name: '0888888888',
        value: '0888888888'
      }, {
        _id: '002',
        name: '0899999999',
        value: '0899999999'
      }]
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'รหัส',
          align: 'center',
          value: 'customer_code',
          sortable: false
        }, {
          text: 'ชื่อ - นามสกุล',
          align: 'center',
          value: 'customer_fnameth',
          sortable: false
        }, {
          text: 'ชื่อเล่น',
          align: 'center',
          value: 'customer_nicknameth',
          sortable: false
        }, {
          text: 'อายุ',
          align: 'center',
          value: 'customer_birthdate',
          sortable: false
        }, {
          text: 'ยอดเงินที่ชำระแล้ว',
          align: 'center',
          value: 'customer_paid_cost',
          sortable: false
        }, {
          text: 'ยอดเงินค้างชำระ',
          align: 'center',
          value: 'customer_remain_cost',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    select_filter_search () {
      const self = this
      if (self.select_filter_search === 'ทั้งหมด') {
        self.filter_search = ''
      } else {
        self.filter_search = self.select_filter_search
      }
    },
    search_fname () {
      const self = this
      if (self.search_fname.match(self.lettersEN)) {
        self.itemsNameFilterLG = 'nameEN'
      } else {
        self.itemsNameFilterLG = 'nameTH'
      }
    },
    search_lname () {
      const self = this
      if (self.search_lname.match(self.lettersEN)) {
        self.itemsNameFilterLG = 'nameEN'
      } else {
        self.itemsNameFilterLG = 'nameTH'
      }
    }
  },
  methods: {
    formatCurrency (cost) {
      const formatter = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 2
      })
      return (formatter.format(cost)).replace('฿', '')
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    addItem () {
      const self = this
      self.boolAddItems = true
      self.showDialog = true
    },
    editItem (item) {
      const self = this
      self.dataDialog = item
      self.boolEditItems = true
      self.showDialog = true
    },
    deleteItem (item) {
      const self = this
      self.dataDialog = item
      self.boolDelItems = true
      self.showDialog = true
    },
    closeDialog () {
      const self = this
      self.boolAddItems = false
      self.boolViewItems = false
      self.boolEditItems = false
      self.boolDelItems = false
      self.showDialog = false
      self.dataDialog = {}
      self.cansave = true
    },
    async submitDialog () {
      const self = this
      if (self.boolAddItems) {
        if (self.dataDialog.product_group_name && self.dataDialog.product_type) {
          swal('สำเร็จ', 'เพิ่มข้อมูลลูกค้าสำเร็จ', 'success', {
            button: false,
            timer: 2000
          })
        } else {
          swal('คำเตือน', 'กรุณากรอกข้อมูลลูกค้าให้ครบถ้วน', 'warning', {
            button: false,
            timer: 3000
          })
        }
      } else if (self.boolEditItems) {
        if (self.dataDialog.product_group_name && self.dataDialog.product_type) {
          swal('สำเร็จ', 'แก้ไขข้อมูลลูกค้าสำเร็จ', 'success', {
            button: false,
            timer: 2000
          })
        } else {
          swal('คำเตือน', 'กรุณากรอกข้อมูลลูกค้าให้ครบถ้วน', 'warning', {
            button: false,
            timer: 3000
          })
        }
      } else if (self.boolDelItems) {
        swal('สำเร็จ', 'ลบข้อมูลลูกค้าสำเร็จ', 'success', {
          button: false,
          timer: 2000
        })
      } else {
        self.closeDialog()
      }
    },
    searchAllCustomer () {
      const self = this
      self.loadingSearchCustomer = true
      setTimeout(() => {
        self.loadingSearchCustomer = false
      }, 2000)
    },
    goAddNewCustomer () {
      const self = this
      const item = {
        firstname: self.search_fname || self.filter_search_fname,
        lastname: self.search_lname || self.filter_search_lname,
        phonenumber: self.search_phone || self.filter_search_phone
      }
      setTimeout(() => {
        self.$router.push({ name: 'CustomerCreateScreen', params: item })
      }, 200)
    },
    viewCustomer (item) {
      const self = this
      setTimeout(() => {
        self.$router.push({ name: 'CustomerViewScreen', params: { customerItems: item } })
      }, 200)
    }
  }
}
</script>

<style scoped>
@import '../../../styles/Dashboard.css';
@import '../../../styles/Customer.css';
</style>
